import React from "react"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import styles from "../assets/css/404.module.scss"

const NotFoundPage = () => {
  const transition = {
    type: "tween",
    ease: [0.14, 0.8, 0.4, 1],
  }
  const variants = {
    wrapper: {
      enter: {
        transition: {
          staggerChildren: 0.15,
        },
      },
    },
    text: {
      initial: {
        y: "100%",
      },
      enter: {
        y: 0,
        transition: transition,
      },
      leave: {
        y: "-100%",
        transition: transition,
      },
    },
  }
  return (
    <>
      <SEO title="Page not found" />
      <motion.div className={styles.wrapper} variants={variants.wrapper}>
        <div className={styles.textWrapper}>
          <motion.div className={styles.title} variants={variants.text}>
            404
          </motion.div>
        </div>
        <div className={styles.textWrapper + " " + styles.subtitle}>
          <motion.div variants={variants.text}>
            This page does not exist.
          </motion.div>
        </div>
        <div className={styles.textWrapper}>
          <motion.div variants={variants.text}>
            Take a look at my <Link to={"/"}>work</Link> instead!
          </motion.div>
        </div>
      </motion.div>
    </>
  )
}

export default NotFoundPage
